import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGem, faRingsWedding, faHeart } from '@fortawesome/pro-light-svg-icons'

import { Heading } from '../shared'
import { FormattedMessage } from 'react-intl'

const Section = styled.section`
  position: relative;
  margin: auto;
  max-width: 1200px;
  flex-shrink: 0;
  margin-bottom: 12rem;
  display: flex;
  padding: 0 12px;

  @media (min-width: 640px) {
    top: -4rem;
  }
`

const Container = styled.div`
  flex: 1;
  margin-top: 2rem;
  width: 100%;
`

const FramePrimary = styled(Img)`
  position: relative;
  width: 100%;
  box-shadow: 0 -1rem 2rem -1rem rgba(0, 0, 0, 0.1);
  flex: 0 1 600px;
  font-size: 0;
  height: 100%;

  display: none;

  @media (min-width: 640px) {
    display: flex;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: calc(100% - 1rem);
    height: calc(100% - 1rem);
    border: 2px solid #ffffff;
    border-radius: 2px;
    top: 0.5rem;
    left: 0.5rem;
  }
`

const Article = styled.article`
  text-align: center;
  @media (min-width: 640px) {
    padding-right: 1rem;
  }
`

const ArticleHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
`

const CardSection = styled.div`
  display: flex;
  position: relative;
  height: 240px;
  overflow: scroll;
  @media (min-width: 640px) {
    overflow: initial;
    height: 271px;
  }
`

const CardGroup = styled.section`
  position: absolute;
  background-color: white;
  border-top: 1rem solid white;
  border-right: 1rem solid white;
  border-bottom: 1rem solid white;
  z-index: 10;
  display: flex;
  justify-content: space-between;
`

const Card = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  flex-wrap: nowrap;
  flex: 1 1 33%;
  overflow: hidden;
  margin-right: 1rem;

  @media (min-width: 640px) {
    width: 200px;
  }

  &:last-child {
    margin-right: 0;
  }
`

const CardImage = styled(Img)`
  position: relative;
  width: 150px;
  height: 150px;
  z-index: 10;

  @media (min-width: 640px) {
    width: 200px;
    height: 200px;
  }
`

const CardDescription = styled.div`
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background: #ffffff;
  align-self: flex-end;
  width: 100%;
  padding: 0.5rem;
  font-size: 0.75rem;
  text-align: center;
`

const P = styled.p`
  margin-bottom: 0;
  text-align: left;
`

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 600, duotone: { shadow: "#000025", highlight: "#f5f5f5" }) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const smallImage = graphql`
  fragment smallImage on File {
    childImageSharp {
      fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

const Story: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      kilkenny: file(relativePath: { eq: "kilkenny.jpg" }) {
        ...fluidImage
      }
      sneakers: file(relativePath: { eq: "sneakers.jpg" }) {
        ...smallImage
      }
      family: file(relativePath: { eq: "family.jpg" }) {
        ...smallImage
      }
      engaged: file(relativePath: { eq: "engaged.jpg" }) {
        ...smallImage
      }
    }
  `)
  return (
    <Section id="a-story">
      <Container>
        <Article>
          <ArticleHeader>
            <Heading id="story_headline" />
          </ArticleHeader>
          <P>
            <FormattedMessage id={'story_0'} />
          </P>
          <P>
            <FormattedMessage id={'story_1'} />
          </P>
          <P>
            <FormattedMessage id={'story_2'} />
          </P>
          <P>
            <FormattedMessage id={'story_3'} />
          </P>
          <CardSection>
            <CardGroup>
              <Card>
                <CardImage fluid={data.engaged.childImageSharp.fluid} imgStyle={{ objectFit: 'cover' }} alt="engaged" />
                <CardDescription>
                  falling in love <FontAwesomeIcon icon={faHeart} fixedWidth color="#000025" size="1x" />
                </CardDescription>
              </Card>
              <Card>
                <CardImage
                  fluid={data.sneakers.childImageSharp.fluid}
                  imgStyle={{ objectFit: 'cover' }}
                  alt="sneaker"
                />
                <CardDescription>
                  getting engaged <FontAwesomeIcon icon={faGem} fixedWidth color="#000025" size="1x" />
                </CardDescription>
              </Card>

              <Card>
                <CardImage fluid={data.family.childImageSharp.fluid} imgStyle={{ objectFit: 'cover' }} alt="family" />
                <CardDescription>
                  still running strong <FontAwesomeIcon icon={faRingsWedding} fixedWidth color="#000025" size="1x" />
                </CardDescription>
              </Card>
            </CardGroup>
          </CardSection>
          <P>
            <FormattedMessage id={'story_4'} />
          </P>
          <P>
            <FormattedMessage id={'story_5'} />
          </P>
          <P>
            <FormattedMessage id={'story_6'} />
          </P>
        </Article>
      </Container>
      <FramePrimary fluid={data.kilkenny.childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} alt="Kilkenny" />
    </Section>
  )
}

export default Story
