import React from 'react'
import styled from 'styled-components'
import { lighten } from 'polished'

const Container = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  z-index: 0;
  margin-top: -6.75rem;

  @media (min-width: 640px) {
    margin-top: -7.25rem;
  }
`

const Item = styled.div`
  text-rendering: optimizeLegibility;
  font-weight: 400;
  letter-spacing: -0.5px;
  position: relative;
  width: 65px;
  padding-top: 1rem;
  padding-bottom: 0.75rem;
  border: 2px solid #6fd8b2;
  border-radius: 6px;
  font-size: 2rem;
  text-align: center;
  color: #6fd8b2;
  text-shadow: 2px 2px ${lighten(0.05, '#6FD8B2')};

  @media (min-width: 640px) {
    width: 85px;
    padding-top: 1.25rem;
    padding-bottom: 1rem;
    font-size: 2.5rem;
    text-shadow: 3px 3px ${lighten(0.05, '#6FD8B2')};
  }

  &::before {
    content: '';
    position: absolute;
    top: calc(50% + 4px);
    left: 3px;
    display: block;
    height: calc(50% + 2px);
    width: calc(100% + 2px);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: ${lighten(0.2, '#6FD8B2')};
    z-index: -1;
  }

  & + & {
    margin-left: 0.75rem;
  }
`

const Rolodex: React.FC = () => {
  return (
    <Container>
      <Item>11</Item>
      <Item>10</Item>
      <Item>19</Item>
    </Container>
  )
}

export default Rolodex
