import React, { Component } from 'react'
import { Layer, Feature } from 'react-mapbox-gl'
import styled from 'styled-components'

const Container = styled.div`
  height: 300px;
  overflow: hidden;
`

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
let mapboxgl
let ReactMapboxGl = {}

if (typeof window !== `undefined`) {
  mapboxgl = require('mapbox-gl')
  ReactMapboxGl = require('react-mapbox-gl')
} else {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  ReactMapboxGl.Map = () => {
    return class Mock extends React.Component {
      constructor() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        super()
      }
      render() {
        return <div />
      }
    }
  }
}
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const Map = ReactMapboxGl.Map({
  accessToken: 'pk.eyJ1IjoiamN0ZiIsImEiOiJjazBlYWthNnowMTMyM2hvNmcxemNmcWowIn0.jgvErE9-iiGh7GVBeYngew',
  minZoom: 8,
  maxZoom: 15,
})

interface Props {
  center: [number, number]
  zoom: number
  icon: string
}

class Mapper extends Component<Props> {
  constructor(props: Props) {
    super(props)
  }

  render() {
    return (
      <Container>
        <Map
          ref={e => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            this.map = e
          }}
          style="mapbox://styles/jctf/ck0t88pgr0i3j1cm830khj666"
          center={this.props.center}
          zoom={[this.props.zoom]}
          containerStyle={{ flex: 1, height: '100%' }}
        >
          <Layer type="symbol" id="marker" layout={{ 'icon-image': this.props.icon }}>
            <Feature coordinates={this.props.center} />
          </Layer>
        </Map>
      </Container>
    )
  }
}

export default Mapper
