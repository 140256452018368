import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { Heading } from '../shared'
import { Mapper } from './location'
import { graphql, useStaticQuery, Link } from 'gatsby'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faParking, faChurch, faHotel } from '@fortawesome/pro-light-svg-icons'

const Section = styled.section`
  background-color: #ffffff;
  padding-bottom: 4rem;
  padding: 0 12px;
`

const Container = styled.div`
  position: relative;
  margin: auto;
  max-width: 980px;
  flex-shrink: 0;
`

const Article = styled.article`
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  display: flex;
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.1);
`

const ArticleLast = styled(Article)`
  margin-top: 4rem;
`

const Location = styled.div`
  position: relative;
  flex-basis: 100%;
  @media (min-width: 640px) {
    flex-basis: 43.33%;
  }
`

const Map = styled.div`
  position: relative;
  flex-basis: 100%;
  @media (min-width: 640px) {
    flex-basis: 56.67%;
  }
`

const Description = styled.div`
  position: relative;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;

  padding: 15px;

  .${Location} & {
    padding: 15px;

    @media (min-width: 640px) {
      padding: 30px 30px 40px 0;
    }
  }

  .${Map} & {
    padding: 15px;

    @media (min-width: 640px) {
      padding: 30px 30px 40px;
    }
  }

  a {
    color: inherit;
  }
`

const Picture = styled(Img)`
  display: flex;
  width: 100%;
  height: 300px;
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: calc(100% - 1rem);
    height: calc(100% - 1rem);
    border: 2px solid #ffffff;
    border-radius: 2px;
    top: 0.5rem;
    left: 0.5rem;
  }
`

const Items = styled.div`
  display: flex;
`

const Item = styled.div`
  flex: 1;
  display: flex;
`

const Title = styled.p`
  font-family: Heebo, sans-serif;
  padding-top: 0.25rem;
  font-size: 1rem;
  text-transform: uppercase;
  margin: 0;
  font-weight: 500;
`

export const venueImage = graphql`
  fragment venueImage on File {
    childImageSharp {
      fluid(maxWidth: 300, duotone: { shadow: "#000025", highlight: "#f5f5f5" }) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

const Venue: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      dansfort: file(relativePath: { eq: "dansfort.jpg" }) {
        ...venueImage
      }
      newpark: file(relativePath: { eq: "newpark.jpg" }) {
        ...venueImage
      }
    }
  `)

  return (
    <Section>
      <Container id="the-venues">
        <Heading id="venue_headline" />
        <Article id="venue-michael-church">
          <Location>
            <Picture fluid={data.dansfort.childImageSharp.fluid} imgStyle={{ objectFit: 'cover' }} alt="dansfort" />
            <Description>
              <Title>St&apos;s Michael Church</Title>
              <a href="https://www.google.com/maps/dir//St+Michaels+National+School,+Saint+Michael's+National+School,+Danesfort,+Co.+Kilkenny,+R95+X527,+Ireland/@51.4065542,-7.7292731,5z/data=!4m8!4m7!1m0!1m5!1m1!1s0x485d3164bf3aa387:0xbd0a27809cd4cd89!2m2!1d-7.2416512!2d52.5828769">
                <span>
                  Danesfort
                  <br />
                  Co. Kilkenny, Ireland
                </span>
              </a>
            </Description>
          </Location>
          <Map>
            {typeof window !== 'undefined' && <Mapper center={[-7.243392, 52.583219]} zoom={13} icon={'church'} />}
            <Description>
              <Items>
                <Item>
                  <FontAwesomeIcon icon={faParking} fixedWidth color="#6FD8B2" size="2x" />
                  <div>
                    <Title>Parking</Title>
                    <p>
                      <a href="https://www.google.com/maps/dir//St+Michaels+National+School,+Saint+Michael's+National+School,+Danesfort,+Co.+Kilkenny,+R95+X527,+Ireland/@51.4065542,-7.7292731,5z/data=!4m8!4m7!1m0!1m5!1m1!1s0x485d3164bf3aa387:0xbd0a27809cd4cd89!2m2!1d-7.2416512!2d52.5828769">
                        Danesfort
                        <br />
                        Co. Kilkenny, Ireland
                      </a>
                    </p>
                    <p>
                      Next to the St&apos;s Michael&apos;s National School, there are plenty of parking space availible.
                    </p>
                  </div>
                </Item>
                <Item>
                  <FontAwesomeIcon icon={faChurch} fixedWidth color="#6FD8B2" size="2x" />
                  <div>
                    <Title>Wedding</Title>
                    <p>Ceremony to be held indoor at the Church</p>
                  </div>
                </Item>
              </Items>
            </Description>
          </Map>
        </Article>

        <ArticleLast id="venue-newpark-hotel">
          <Location>
            <Picture fluid={data.newpark.childImageSharp.fluid} imgStyle={{ objectFit: 'cover' }} alt="newpark" />
            <Description>
              <Title>Newpark Hotel</Title>
              <a href="https://www.google.com/maps/dir//Newpark+Hotel+Kilkenny,+Castlecomer+Rd,+Newpark+Lower,+Kilkenny,+R95+KP63,+Ireland/@52.6643362,-7.285598,13z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x485d3d19883a19e7:0xe259894096c48726!2m2!1d-7.2504929!2d52.6643414">
                Castlecomer Road
                <br />
                Co. Kilkenny, Ireland
              </a>
            </Description>
          </Location>
          <Map>
            {typeof window !== 'undefined' && <Mapper center={[-7.2506109, 52.6650181]} zoom={15} icon={'glass'} />}
            <Description>
              <Items className="venue__items flex-container">
                <Item className="venue__item">
                  <FontAwesomeIcon icon={faParking} fixedWidth color="#6FD8B2" size="2x" />
                  <div>
                    <Title>Parking</Title>
                    <p>
                      <a href="https://www.google.com/maps/dir//Newpark+Hotel+Kilkenny,+Castlecomer+Rd,+Newpark+Lower,+Kilkenny,+R95+KP63,+Ireland/@52.6643362,-7.285598,13z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x485d3d19883a19e7:0xe259894096c48726!2m2!1d-7.2504929!2d52.6643414">
                        Castlecomer Road
                        <br />
                        Co. Kilkenny, Ireland
                      </a>
                    </p>
                    <p>Outdoor parking space next to Newpark Hotel</p>
                  </div>
                </Item>
                <Item>
                  <FontAwesomeIcon icon={faHotel} fixedWidth color="#6FD8B2" size="2x" />
                  <div>
                    <Title>Newpark Hotel</Title>
                    <p>The brunch reception & restaurant is inside the Newpark Hotel on the ground floor.</p>
                  </div>
                </Item>
              </Items>
            </Description>
          </Map>
        </ArticleLast>
      </Container>
    </Section>
  )
}

export default Venue
