import React from 'react'
import styled from 'styled-components'
import { InjectedIntl, injectIntl } from 'react-intl'
import { Rolodex, Timeline } from './plan/'
import { Heading } from '../shared'

const Section = styled.section`
  background-color: #f5f5f5;
  padding-top: 4rem;
  padding-bottom: 4rem;
  border-left: 12px solid #fff;
  border-right: 12px solid #fff;
`

const Container = styled.div`
  position: relative;
  margin: auto;
  max-width: 980px;
  flex-shrink: 0;
`

interface Props {
  intl: InjectedIntl
}

const Plan: React.FC<Props> = ({ intl }) => (
  <Section id="the-plan">
    <Container>
      <Rolodex />
      <Heading id={'plan_headline'} />
      <Timeline intl={intl} />
    </Container>
  </Section>
)

export default injectIntl(Plan)
