import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { InjectedIntl, FormattedMessage } from 'react-intl'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faRingsWedding,
  faChurch,
  faGlassCheers,
  faCompactDisc,
  faPopcorn,
  faCocktail,
  faUtensils,
  faBeer,
} from '@fortawesome/pro-light-svg-icons'

const Container = styled.div`
  padding: 0 20px 0 0;

  @media (min-width: 420px) {
    padding: 0;
  }
`

const Group = styled.div`
  display: flex;
  justify-content: center;
`

const Icon = styled.div`
  position: relative;
  padding: 1rem;
  min-width: 100px;

  @media (min-width: 640px) {
    min-width: 300px;
  }
  // prettier-ignore
  ${Group}:nth-child(even) & {
    @media (min-width: 640px) {
      text-align: right;
    }
  }
  // prettier-ignore
  ${Group}:nth-child(odd) & {
    @media (min-width: 640px) {
      order: 2;
    }
  }
`

const Event = styled.div`
  border-left: 2px solid #000025;
  max-width: 300px;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  
  // prettier-ignore
  ${Group}:nth-child(odd) & {
    @media (min-width: 640px) {
      order: 1;
      border-left: initial;
      border-right: 2px solid #000025;
      max-width: 303px;
    }
  }
}
`

const Title = styled.p`
  font-family: Heebo, sans-serif;
  position: relative;
  border-bottom: 2px dotted #000025;
  padding-bottom: 0.25rem;
  font-size: 1rem;
  text-transform: uppercase;
  margin: 0 0 0.5rem 0;
  font-weight: 500;

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 8px;
    height: 8px;
    border: 2px solid #000025;
    border-radius: 50%;
    background-color: #6fd8b2;
    left: -5px;
  }
  // prettier-ignore
  ${Group}:nth-child(odd) & {
    @media (min-width: 640px) {
      &::after {
        left: initial;
        right: -5px;
      }
    }
  }
`

const Time = styled.span`
  display: inline-block;
  margin-right: 0.5rem;

  @media (min-width: 420px) {
    margin-right: 1rem;
  }

  &::after {
    content: '\\02219';
    display: inline-block;
    margin-left: 0.5rem;

    @media (min-width: 420px) {
      margin-left: 1rem;
    }
  }

  @media (min-width: 1024px) {
    position: absolute;
    display: block;
    right: -4.5rem;
    top: 0.75rem;
    margin: 0;
    font-size: 1.25rem;

    &::after {
      content: none;
    }
  }
  // prettier-ignore
  ${Group}:nth-child(odd) & {
    @media (min-width: 1024px) {
      right: initial;
      left: -4.5rem;
    }
  }
`

const Description = styled.div`
  padding: 1rem;
  padding-top: 0.25rem;

  a {
    color: inherit;
  }
`

const Notice = styled.div`
  font-size: 12px;
  padding-top: 2rem;
  text-align: center;
`

interface Props {
  intl: InjectedIntl
}

const Timeline: React.FC<Props> = ({ intl }) => (
  <Container>
    <Group>
      <Icon>
        <FontAwesomeIcon icon={faRingsWedding} fixedWidth color="#6FD8B2" size="2x" />
        <FontAwesomeIcon icon={faChurch} fixedWidth color="#6FD8B2" size="2x" />
      </Icon>
      <Event>
        <Title>
          <Time>1 pm</Time>
          <FormattedMessage id={'timeline_0_title'} />
        </Title>
        <Description>
          <FormattedMessage
            id={'timeline_0_paragraph'}
            values={{
              link: (
                <Link to={intl.locale === 'de' ? 'de/#venue-michael-church' : '/#venue-michael-church'}>
                  <span>St&apos;s Michael Church</span>
                </Link>
              ),
            }}
          />
        </Description>
      </Event>
    </Group>
    <Group>
      <Icon>
        <FontAwesomeIcon icon={faBeer} fixedWidth color="#6FD8B2" size="2x" />
        <FontAwesomeIcon icon={faPopcorn} fixedWidth color="#6FD8B2" size="2x" />
      </Icon>
      <Event>
        <Title>
          <Time>3 pm</Time>
          <FormattedMessage id={'timeline_1_title'} />
        </Title>
        <Description>
          <FormattedMessage
            id={'timeline_1_paragraph'}
            values={{
              link: (
                <Link to={intl.locale === 'de' ? 'de/#venue-newpark-hotel' : '/#venue-newpark-hotel'}>
                  <span>Newpark Hotel</span>
                </Link>
              ),
            }}
          />
        </Description>
      </Event>
    </Group>
    <Group>
      <Icon>
        <FontAwesomeIcon icon={faGlassCheers} fixedWidth color="#6FD8B2" size="2x" />
        <FontAwesomeIcon icon={faUtensils} fixedWidth color="#6FD8B2" size="2x" />
      </Icon>
      <Event>
        <Title>
          <Time>~ 6 pm</Time>
          <FormattedMessage id={'timeline_2_title'} />
        </Title>
        <Description>
          <FormattedMessage
            id={'timeline_2_paragraph'}
            values={{
              link: (
                <Link to={intl.locale === 'de' ? 'de/#venue-newpark-hotel' : '/#venue-newpark-hotel'}>
                  <span>Newpark Hotel</span>
                </Link>
              ),
            }}
          />
        </Description>
      </Event>
    </Group>
    <Group>
      <Icon>
        <FontAwesomeIcon icon={faCompactDisc} fixedWidth color="#6FD8B2" size="2x" />
        <FontAwesomeIcon icon={faCocktail} fixedWidth color="#6FD8B2" size="2x" />
      </Icon>
      <Event>
        <Title>
          <Time>~ 9 pm</Time>
          <FormattedMessage id={'timeline_3_title'} />
        </Title>
        <Description>
          <FormattedMessage
            id={'timeline_3_paragraph'}
            values={{
              link: (
                <Link to={intl.locale === 'de' ? 'de/#venue-newpark-hotel' : '/#venue-newpark-hotel'}>
                  <span>Newpark Hotel</span>
                </Link>
              ),
            }}
          />
        </Description>
      </Event>
    </Group>
    <Notice>
      <FormattedMessage id={'timeline_notice'} />
    </Notice>
  </Container>
)

export default Timeline
