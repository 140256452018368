import React from 'react'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'
import { Heading } from '../shared'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo, faMobile } from '@fortawesome/pro-light-svg-icons'

const sitters = [
  { name: 'Geraldine Carey (NVB)', phone: '+353861529265', notic: '' },
  { name: 'Margaret Lanigan (NVB)', phone: '+353876497489', notic: '' },
  { name: 'Maura O’Shea (NVB)', phone: '+353861679170', notic: '' },
  { name: 'Mags O’Keeffe (NVB)', phone: '+353861658573', notic: '' },
  { name: 'Deirdre Dewberry (NVB)', phone: '+353857046198', notic: '' },
  { name: 'Anne Young (NVB)', phone: '+353870666814', notic: '' },
  { name: 'Mary Delaney (NVB)', phone: '+353860853218', notic: '' },
  { name: 'Anne Jordan (NVB)', phone: '+353871207932', notic: '' },
  { name: 'Tamsin English (NVB)', phone: '+353872412139', notic: '(weekends & school Holidays)' },
]

const Section = styled.section`
  background-color: #ffffff;
  border-left: 12px solid #fff;
  border-right: 12px solid #fff;
`

const Container = styled.div`
  position: relative;
  margin: auto;
  max-width: 980px;
  flex-shrink: 0;
`

const List = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (min-width: 420px) {
    justify-content: space-between;
  }
`

const Item = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Name = styled.p`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const Smallprint = styled.span`
  font-size: 10px;
`

const Notice = styled.p`
  color: #6fd8b2;
  font-size: 12px;
`

const Babysitters: React.FC = () => (
  <Section id="babysitters">
    <Container>
      <Heading id={'babysitters_headline'} />
      <p>
        Below is a list of babysitters which the hotel can recommend, all babysitters listed below are experienced and
        Garda vetted.
      </p>
      <p>
        <FontAwesomeIcon icon={faInfo} fixedWidth color="#6FD8B2" size="1x" />
        Rates may vary depending on the number of kids & ages.
      </p>
      <p>
        <FontAwesomeIcon icon={faInfo} fixedWidth color="#6FD8B2" size="1x" />
        Midnight rate might apply.
      </p>
      <p>
        <FontAwesomeIcon icon={faInfo} fixedWidth color="#6FD8B2" size="1x" />
        For health & safety reasons it is recommended one babysitter to every three kids.
      </p>
      <p>
        <FontAwesomeIcon icon={faInfo} fixedWidth color="#6FD8B2" size="1x" />
        You are advised to inform the babysitter of any illnesses or allergies your child may have.
      </p>
      <p>
        <FontAwesomeIcon icon={faInfo} fixedWidth color="#6FD8B2" size="1x" />
        All babysitter listed are Garda vetted NVB (National Vetting Bureau)
      </p>
      <List>
        {sitters.map((sitter, idx) => (
          <Item key={idx}>
            <Name>
              <span>{sitter.name}</span>
              <Smallprint>{sitter.phone.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</Smallprint>
              {sitter.notic && <Smallprint>{sitter.notic}</Smallprint>}
            </Name>
            <a href={`tel:${sitter.phone}`}>
              <FontAwesomeIcon icon={faMobile} fixedWidth color="#6FD8B2" size="2x" />
            </a>{' '}
          </Item>
        ))}
      </List>
      <Notice>We would like to emphasise that we do not take any liability</Notice>
    </Container>
  </Section>
)

export default injectIntl(Babysitters)
