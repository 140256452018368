import React from 'react'
import styled from 'styled-components'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { lighten } from 'polished'
import { FormattedDate, FormattedMessage, InjectedIntl, injectIntl } from 'react-intl'

const Container = styled.section`
  border-left: 12px solid #fff;
  border-right: 12px solid #fff;
  width: 100%;
  min-height: 450px;
  height: 100%;
  background-color: #6fd8b2;
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding-bottom: 3rem;

  @media (min-width: 640px) {
    height: 700px;
    padding-bottom: 0;
  }
`

const Group = styled.div`
  margin: 0 auto;
  position: relative;
  display: flex;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 6px;
    width: 100%;
    height: 100%;
    border: 2px solid #fff;
  }
`

const Details = styled.div`
  color: #000025;
  padding: 1rem 1.5rem 0.75rem;
  border: 2px solid #fff;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1.5px;
  font-size: 0.88rem;
  width: 160px;

  @media (min-width: 640px) {
    padding: 1.25rem 2rem 1rem;
    font-size: 1rem;
    width: auto;
  }

  @media (min-width: 1024px) {
    padding: 1.25rem 3rem 1rem;
    font-size: 1.1rem;
    width: auto;
  }

  & + & {
    border-left: 0;
  }
`

const Btn = styled(Link)`
  background-color: #000025;
  border: none;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  font-size: 0.88rem;
  font-weight: 700;
  letter-spacing: 0.08rem;
  padding: 1.1rem 3rem 0.9rem;
  text-transform: uppercase;
  margin: 40px auto 0 auto;
  text-decoration: none;

  &:hover {
    background-color: ${lighten(0.1, '#000025')};
  }
`

const Married = styled.div`
  margin-bottom: 2rem;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Rings = styled(Img)`
  height: 200px;
  width: 200px;

  @media (min-width: 640px) {
    height: 300px;
    width: 300px;
  }
`
interface Props {
  intl: InjectedIntl
}

const Banner: React.FC<Props> = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      kilkenny: file(relativePath: { eq: "icon-white.png" }) {
        ...smallImage
      }
    }
  `)

  return (
    <Container>
      <Married>
        <Rings fluid={data.kilkenny.childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} alt="rings" />
      </Married>
      <Group>
        <Details>
          <FormattedDate
            value={new Date(1570798800000)}
            year="numeric"
            month="short"
            day="2-digit"
            weekday="long"
            timeZone={'UTC'}
          />
        </Details>
        <Details>
          Kilkenny, <span>Ireland</span>
        </Details>
      </Group>
      <Btn to={intl.locale === 'de' ? 'de/#the-venues' : '/#the-venues'}>
        <FormattedMessage id="venue_title" />
      </Btn>
      <Btn to={intl.locale === 'de' ? 'de/#babysitters' : '/#babysitters'}>
        <FormattedMessage id="babysitters_headline" />
      </Btn>
    </Container>
  )
}

export default injectIntl(Banner)
